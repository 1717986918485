<template>
	<!-- Start Template Root Div -->
	<div>
		<WaveLoader v-if="searchSurfWaiversLoading" />

		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">
								Customer Check-In
							</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<section class="px-4 mb-5">
				<p class="w-10/12">
					You can check the following people in, please choose who is
					present with you now. If people are travelling separately,
					or not at all, please let us know below.
				</p>
			</section>

			<section class="px-4 mb-15">
				<div
					class="w-1/2 md:w-1/3 flex ml-auto -mr-2 lg:-mr-4 justify-around items-center"
				>
					<p class="w-auto text-sm mx-2 lg:mx-4">
						Check-In Now
					</p>
					<p class="w-auto text-sm mx-2 lg:mx-4">
						Check-In Later
					</p>
					<p class="w-auto text-sm mx-2 lg:mx-4">
						Couldn't Make It
					</p>
				</div>
				<div
					v-for="surfer in surfersToCheckIn"
					:key="surfer.uuid"
					class="flex flex-wrap items-center justify-between -mx-2 lg:-mx-4"
				>
					<div class="flex-auto md:flex justify-around items-center">
						<div class="md:w-1/2 m-2 lg:m-4">
							<p>{{ surfer.lastName }}, {{ surfer.firstName }}</p>
							<p class="text-xs" v-html="surfer.sessionNames"></p>
						</div>
						<div class="md:w-1/2 m-2 lg:m-4">
							<p>Session(s) at {{ surfer.sessionTimes }}</p>
						</div>
					</div>
					<div
						class="w-1/2 md:w-1/3 m-2 lg:m-4 flex justify-around items-center"
					>
						<div class="w-auto">
							<span
								role="checkbox"
								:aria-checked="surfer.onSite"
								tabindex="0"
								@click="handleCheckInToggle(surfer, 'onSite')"
								:class="
									surfer.onSite
										? 'bg-pear-600'
										: 'bg-gray-400'
								"
								class="relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
							>
								<span
									aria-hidden="true"
									:class="
										surfer.onSite
											? 'translate-x-5'
											: 'translate-x-0'
									"
									class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
								></span>
							</span>
						</div>
						<div class="w-auto">
							<span
								role="checkbox"
								:aria-checked="surfer.checkInLater"
								tabindex="0"
								@click="
									handleCheckInToggle(surfer, 'checkInLater')
								"
								:class="
									surfer.checkInLater
										? 'bg-pear-600'
										: 'bg-gray-400'
								"
								class="relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
							>
								<span
									aria-hidden="true"
									:class="
										surfer.checkInLater
											? 'translate-x-5'
											: 'translate-x-0'
									"
									class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
								></span>
							</span>
						</div>
						<div class="w-auto">
							<span
								role="checkbox"
								:aria-checked="surfer.noShow"
								tabindex="0"
								@click="handleCheckInToggle(surfer, 'noShow')"
								:class="
									surfer.noShow
										? 'bg-pear-600'
										: 'bg-gray-400'
								"
								class="relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
							>
								<span
									aria-hidden="true"
									:class="
										surfer.noShow
											? 'translate-x-5'
											: 'translate-x-0'
									"
									class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
								></span>
							</span>
						</div>
					</div>
				</div>
			</section>

			<section class="px-4 mb-15">
				<p
					class="mb-5 w-10/12"
					v-if="surfersNotCheckIn && surfersNotCheckIn.length > 0"
				>
					You can't check-in people over 18, who need to sign a
					waiver. The following people will need to go through this
					check-in proccess separately at another terminal, or after
					you at this terminal.
				</p>
				<div
					v-for="surfer in surfersNotCheckIn"
					:key="surfer.uuid"
					class="flex items-center justify-around py-5"
				>
					<div class="w-3/12">
						<p>{{ surfer.lastName }}, {{ surfer.firstName }}</p>
						<p class="text-xs" v-html="surfer.sessionNames"></p>
					</div>
					<div class="w-9/12">
						<p>Session(s) at {{ surfer.sessionTimes }}</p>
					</div>
				</div>
			</section>

			<section class="px-4 pb-10">
				<button
					type="button"
					class="w-1/2 md:w-1/3 lg:w-1/4 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					@click="checkInSurfers"
					aria-label="Continue Wave Check In Process"
				>
					Continue
				</button>
			</section>
		</main>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		components: {
			WaveLogo,
			WaveLoader
		},
		data: () => ({
			surname: "", // Mutiple accounts and waivers
			accountAk: ""
		}),
		created() {
			if (this.searchSurfWaiversActiveUser === null) {
				this.$router.push({ name: "CheckIn" });
			} else {
				this.searchSessions();
			}
		},
		computed: {
			...mapState("surfers", [
				"searchSurfWaiversLoading",
				"searchSurfWaiversResults",
				"searchSurfWaiversSuccess",
				"searchSurfWaivers",
				"searchSurfWaiversActiveUser"
			]),
			activeDate() {
				return this.$moment()
					.utc()
					.startOf("day")
					.toISOString();
			},
			surfersToCheckIn() {
				let surfers = this.searchSurfWaivers.filter(
					surfer =>
						surfer.firstName === this.searchSurfWaiversActiveUser ||
						surfer.age < 18 ||
						surfer.hasSignedWaiver ||
						surfer.sessionNames.toLowerCase() ===
							"spectator admission" ||
						surfer.sessionNames.toLowerCase() ===
							"under 6 spectator admission"
				);

				surfers.forEach(surfer => {
					surfer.onSite = true;
				});

				return surfers;
			},
			surfersNotCheckIn() {
				return this.searchSurfWaivers.filter(
					surfer =>
						surfer.firstName !== this.searchSurfWaiversActiveUser &&
						surfer.age >= 18 &&
						surfer.onSite === false &&
						surfer.hasSignedWaiver === false &&
						!(
							surfer.sessionNames.toLowerCase() ===
								"spectator admission" ||
							surfer.sessionNames.toLowerCase() ===
								"under 6 spectator admission"
						)
				);
			}
		},
		methods: {
			...mapActions([
				"surfers/searchSurfersWaivers",
				"surfers/staticCreateSurferCheckIn",
				"surfers/staticUpdateSurfersWaiver"
			]),
			...mapMutations({
				addSurferToCheckIn: "surfers/ADD_PENDING_CHECKIN"
			}),
			searchSessions() {
				// Utilzie Reservation AK if it's set
				if (this.$route.params.reservationAk != "unreserved")
					this["surfers/searchSurfersWaivers"]({
						date: this.activeDate,
						reservation_ak: this.$route.params.reservationAk
					});
				else
					this["surfers/searchSurfersWaivers"]({
						date: this.activeDate,
						last_name: this.$route.params.lastName
					});
			},
			handleCheckInToggle(surfer, toToggle) {
				surfer.onSite = false;
				surfer.noShow = false;
				surfer.checkInLater = false;

				surfer[toToggle] = true;
			},
			checkInSurfers() {
				let verificationNeeded = false;
				let disclaimerNeeded = false;

				this.searchSurfWaivers.forEach(surfer => {
					let serverObjects = this.searchSurfWaiversResults[
						surfer.firstName
					];

					//create a check in call
					if (surfer.onSite === true) {
						serverObjects.forEach(obj => {
							// Disclaimer Check
							if (
								surfer.onSite === true &&
								surfer.hasSignedWaiver === false &&
								!(
									surfer.sessionNames.toLowerCase() ===
										"spectator admission" ||
									surfer.sessionNames.toLowerCase() ===
										"under 6 spectator admission"
								)
							)
								disclaimerNeeded = true;

							// Verification Check
							if (
								surfer.promotionCode === "LP112025" ||
								surfer.promotionCode === "STUDENTWAVES10" ||
								surfer.promotionCode === "FORCESWAVES15" ||
								surfer.promotionCode === "EMERGENCYWAVES15" ||
								surfer.promotionCode === "VETERANSWAVES15"
							) {
								this.addSurferToCheckIn(obj);
								verificationNeeded = true;
							} else {
								// Construct Object
								const arrival = {
									surfWaiver: obj.uuid,
									location:
										process.env
											.VUE_APP_WAVE_SURF_SITE_BRISTOL_ON_SITE_ID //On Site Location ID
								};

								// Store CheckIn
								this["surfers/staticCreateSurferCheckIn"](
									arrival
								);
							}
						});
					}

					//hande no shows
					if (surfer.noShow === true) {
						serverObjects.forEach(obj => {
							obj.noShow = true;
							obj.activeDate = this.activeDate;
							this["surfers/staticUpdateSurfersWaiver"](obj);
						});
					}
				});

				if (verificationNeeded) {
					if (disclaimerNeeded) {
						this.$router.push({
							name: "CheckInConfirm",
							query: { showDisclaimer: true }
						});
					} else {
						this.$router.push({ name: "CheckInConfirm" });
					}
				} else {
					if (disclaimerNeeded) {
						this.$router.push({ name: "CheckInTerms" });
					} else {
						this.$router.push({ name: "CheckInComplete" });
					}
				}
			}
		}
	};
</script>
